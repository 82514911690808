export const version = "v1.1.3";

export const requestType = [
    'Scheduler',
    'ServiceQualificationByAddress',
    'ServiceQualificationByAddressV2',
    'VisitationBySubscriber',
    'VisitationByAddress',
    'CallBandwidthByAddress',
    'TechParam',
    'SpeedCalculation',
    'SpeedCalculationV2',
    'OSSCHAC_getVisitationBySubscriber',
    'OSSCHAC_GetTechParam',
    'OSSCHAC_NAL',
    'Property',
    'QOS',
    'CPE',
    'Lock/Unlock_Version',
    'Update_VendorResource',
    'TdcWhiteListJob',
    'OpnenetWhiteListJob',
    'UpdateToAccessInventory',
    'ServiceQualificationBynamId',
    'ServiceQualificationByInstallationId',
    'CalcQosNeeded',
    'PreSalesAppointment'
]

export const vendors = [
    { name: "TDC", value: 1 },
    { name: "ENIIG", value: 2 },
    { name: "FIBIA", value: 3 },
    { name: "EWII", value: 4 },
    { name: "SYDENERGI", value: 5 },
    { name: "NORDENERGI", value: 6 },
    { name: "THYMORS", value: 7 },
    { name: "ENERGIFYN", value: 8 },
    { name: "RAH", value: 11 },
    { name: "SEF", value: 12 },
    { name: "GCP", value: 13 }
];

export const vendorList=[
    { name: "OPENNET DELTA", value: 1 },
    { name: "OPENNET FULL", value: 2 },
    { name: "TDC MOBILE", value: 3 },
    { name: "TDC FIBER", value: 4 },
    { name: "TDC COPPER", value: 5 },
    {name: "TDC COAX", value: 6},
    { name: "FIBIA", value: 7 },
    { name: "EWII", value: 8 }
]

export const vendorReqParam=[
    { name: "OPENNET_DELTA", value: 1 },
    { name: "OPENNET_FULL", value: 2 },
    { name: "TDC_MOBILE", value: 3 },
    { name: "TDC_FIBER", value: 4 },
    { name: "TDC_COPPER", value: 5 },
    {name: "TDC_COAX", value: 6},
    { name: "FIBIA", value: 7 },
    { name: "EWII", value: 8 }
]

export const vendorFileName=[
    { name: "SP01_DELTA", value: 1 },
    { name: "SP01_FULL", value: 2 },
    { name: "MobileAddressList", value: 3 },
    { name: "FiberAddressList", value: 4 },
    { name: "CopperAddressList", value: 5 },
    {name: "CoaxAddressList_Nuuday", value: 6},
    { name: "Fibia_Full_NuuOssi_WhiteList", value: 7 }
]

export const retryStatus= ["INTERNAL_ERROR", "EXTERNAL_ERROR","VALIDATED"];

export const transactionStatus = ["VALIDATED", "EXTERNAL_ERROR",
    "INTERNAL_ERROR", "COMPLETED", "ACCESS_INVENTORY_ERROR"];