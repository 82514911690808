import axios from '../../../api/transaction_api';

export const aiStatus = async (vendor) => {
    let result = 0;
    if (vendor === "OPENNET") {
        let e = await axios.get("/v2/accessinventory/status?vendor=ENIIG");
        let s = await axios.get("/v2/accessinventory/status?vendor=SYDENERGI");
        let n = await axios.get("/v2/accessinventory/status?vendor=NORDENERGI");
        let t = await axios.get("/v2/accessinventory/status?vendor=THYMORS");
        result = e.data + s.data + n.data + t.data;
    } else if (vendor === "COAX") {
        let r = await axios.get(`/v2/accessinventory/status?vendor=PRIVATE&uniqueKey=Docsis`);
        result = r.data;
    } else {
        let r = await axios.get(`/v2/accessinventory/status?vendor=${vendor}`);
        result = r.data;
    }
    return result;
}

export const stagingStatus = async (vendor) => {
    let result = 0;
    let r = await axios.get(`/v2/whiteListStaging/status?vendor=${vendor}`);
    result = r.data;
    return result;
}

let uploadUrl = {
    EWII: "/v1/whiteList/ewii/upload",
    TDC: "/v1/whiteList/tdcnet/upload",
    COAX: "/v1/whiteList/tdcnetcoax/upload",
    OPENNET: "/v1/whiteList/loadOpenNetWhiteList",
    FIBIA: "/v1/whiteList/fibia/upload"
}

let fileValidate = {
    TDC: "ftth",
    EWII: "trefor",
    OPENNET: "SP01",
    FIBIA: "Address_file",
    COAX: "COAX"
}

export const fileUpload = async (vendor, file) => {
    if (file.name.includes(fileValidate[vendor])) {
        let formData = new FormData();
        formData.append("file", file);
        try {
            await axios.post(uploadUrl[vendor], formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            return "Done";
        } catch (e) {
            return "Error";
        }
    } else {
        return "Invalid file";
    }

}
