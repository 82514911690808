import React, { useState } from 'react';
import { vendors } from '../../../data/constant';
import axios from '../../../api/transaction_api';
import Notify from '../../common/modal/Notify';

export default function DataTable({ data, setData }) {
  const [notifyData, setNotifyData] = useState({ title: 'title' });

  let retryRequest = (id) => {
    setNotifyData({ title: "Waiting for execute...!" });
    axios.get(`/v1/whiteList/retryWhiteListLoading?transactionId=${id}`).then(res => {
      if (res.data) {
        setData(pre => (pre.map(d => d.transactionId === id ? res.data : d)));
        setNotifyData({ title: "Transuction updated...!" });
      }
    }).catch(err => {
      setNotifyData({ title: "Retry failed...!" });
    })
  }
  let notifyRetry = (id) => {
    setNotifyData(
      {
        title: `Do you want to Update request id: ${id}?`,
        method: () => retryRequest(id)
      });
  }

  return (
    <div style={{ position: 'relative', overflow: 'scroll', textAlign: 'center', maxHeight: "70vh" }}>
      <Notify id="notifyModal" notifyData={notifyData} />
      <table className="table table-sm table-striped">
        <thead className="thead-light">
          <tr>
            <th>Id</th>
            <th>StagingId</th>
            <th>Vendor</th>
            <th>Status</th>
            <th>Msg</th>
            <th>CreatedTime</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return <tr key={index}>
              <td>{item.transactionId}</td>
              <td>{item.stagingId}</td>
              <td>{vendors.find(v => v.value === item.vendorID).name}</td>
              <td>
                {item.transStatus.includes("COMPLETED") ?
                  <button className="btn btn-sm btn-outline-success" disabled>{item.transStatus}</button> :
                  <button className={item.transStatus.includes("ERROR") ?
                    "btn btn-sm btn-outline-danger" : "btn btn-sm btn-outline-success"}
                    data-toggle="modal" data-target='#notifyModal'
                    onClick={() => notifyRetry(item.transactionId)}>{item.transStatus}</button>
                }
              </td>
              <td>{item.transMsg}</td>
              <td>{item.createdTime}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  )
}
