import { faRetweet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { retryStatus, vendors } from '../../../data/constant';
import axios from '../../../api/transaction_api';
import DataTable from './DataTable';

export default function Retry() {
    const [retryData, setRetryData] = useState([]);
    const [validate, setValidate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState("");
    const [retryEnable, setRetryEnable] = useState(false);

    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
        vendor: "",
        transactionalStatus: "ALL",
        pageSize: 10000,
        pageId: 0
    });
    const resetFormData = () => {
        setFormData({
            startDate: "",
            endDate: "",
            vendor: "",
            transactionalStatus: "ALL",
            pageSize: 10000,
            pageId: 0
        });
    }
    const searchError = () => {
        setErr("");
        setRetryData([]);
        setLoading(true);
        setValidate(false);
        setRetryEnable(false);
        if (formData.transactionalStatus === "ALL") {
            retryStatus.forEach(s => {
                getErrors(s).then(res => {
                    setLoading(false);
                    setRetryData(pre => ([...pre, ...res]));
                });
            })
        } else {
            axios.post('/v2/transaction/getTransactionBySearch', formData)
                .then(res => {
                    setLoading(false);
                    setRetryEnable(true);
                    if (res.data.length === 0) {
                        setErr("No data found");
                        setRetryEnable(false);
                    }
                    if (formData.transactionalStatus.includes("VALIDATED")) {
                        setValidate(true);
                    }
                    setRetryData(res.data);
                }).catch(err => {
                    setLoading(false);
                    setRetryEnable(false);
                    if (err.message === "Request failed with status code 504") {
                        setErr("Timeout. Please retry");
                    } else {
                        setErr(err.message);
                    }
                })
        }
    }

    const getErrors = async (status) => {
        let res = await axios.post('/v2/transaction/getTransactionBySearch', { ...formData, transactionalStatus: status });
        return await res.data;
    }

    const ValidateErrorData = () => {
        if (retryData.length > 0) {
            setLoading(true);
            axios.post('/v1/whiteList/retryInvalidTransaction', formData).then(res => {
                setLoading(false);
                if (res.data && res.data.length > 0) {
                    setRetryData(res.data);
                    setValidate(true);
                } else {
                    setValidate(false);
                    setErr("No data validated");
                }
            }).catch(err => {
                setLoading(false);
                setValidate(false);
                setErr(err.message);
            })
        }
    }

    const updateValidate = () => {
        setLoading(true);
        axios.get(`/v1/whiteList/manualUpdateToAccessInventory?vendor=${vendors.find(v => v.value === parseInt(formData.vendor)).name}`).then(res => {
            setLoading(false);
            setRetryData([]);
            setErr("Successfully updated");
        }).catch(err => {
            setLoading(false);
            setErr(err.message);
        })
    }

    return (
        <div className="card">
            <h5 className="card-header">Retry Transaction</h5>
            <div className="card-body">
                <div className="form-row">
                    <div className="form-group col-md">
                        <label htmlFor="vendor">Vendor</label>
                        <select className="form-control form-control-sm" id="vendor"
                            value={formData.vendor} onChange={(e) => setFormData({ ...formData, vendor: e.target.value })}>
                            <option value="" defaultChecked>Select Vendor</option>
                            {vendors.map(ven => <option key={ven.value} value={ven.value}>{ven.name}</option>)}
                        </select>
                    </div>
                    <div className="form-group col-md">
                        <label htmlFor="transactionalStatus">Transactional Status</label>
                        <select className="form-control form-control-sm" id="transactionalStatus"
                            value={formData.transactionalStatus} onChange={(e) => setFormData({ ...formData, transactionalStatus: e.target.value })}>
                            <option value="ALL" defaultChecked>ALL</option>
                            {retryStatus.map(item => <option key={item} value={item}>{item}</option>)}
                        </select>
                    </div>
                    <div className="form-group col-md">
                        <label htmlFor="startDate">Start date</label>
                        <input type="date" placeholder="yyyy-MM-dd" className="form-control form-control-sm" id="startDate"
                            value={formData.startDate} onChange={(e) => setFormData({ ...formData, startDate: e.target.value })} />
                    </div>
                    <div className="form-group col-md">
                        <label htmlFor="endDate">End date</label>
                        <input type="date" className="form-control form-control-sm" id="endDate"
                            value={formData.endDate} onChange={(e) => setFormData({ ...formData, endDate: e.target.value })} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-sm" style={{ textAlign: "center" }}>

                        <button type="submit" className="btn btn-secondary"
                            disabled={!formData.vendor}
                            onClick={() => searchError()}>Search</button>
                        <button type="submit" className="btn btn-secondary ml-2"
                            onClick={() => resetFormData()}>Reset</button>

                    </div>
                </div>

                <div className="div-table mt-3" style={{ background: "#f0f1f375" }}>
                    <div className="row">
                        <div className="col-sm-6 col-6">
                            <button className="btn btn-danger btn-block"
                                disabled={validate || retryData.length === 0 || !retryEnable}
                                onClick={() => ValidateErrorData()}>Validate: {retryData.length > 0 && !validate && retryEnable ? retryData.length : 0} </button>
                        </div>

                        <div className="col-sm-6 col-6 px-3 text-rigt">
                            <button className="btn btn-success btn-block"
                                disabled={!validate || retryData.length === 0 || !retryEnable}
                                onClick={() => updateValidate()}>Update: {retryData.length > 0 && validate ? retryData.length : 0} </button>
                        </div>
                    </div>
                </div>


                <div className="div-table mt-3">
                    {retryData.length > 0 && !loading && !err ? <DataTable data={retryData} setData={setRetryData} /> :
                        <div className="text-center p-4 text-wrap">
                            <FontAwesomeIcon className="mb-3" icon={faRetweet} size="5x" color="rgb(87, 179, 193)"
                                spin={loading} />
                            <h2 style={{ color: "rgb(73, 80, 87)" }}>
                                {loading ? "Loading" :
                                    err ? err : "Retry WhiteList"}</h2>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}
