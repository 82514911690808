import React, { useEffect, useState } from 'react';
import { aiStatus, stagingStatus, fileUpload } from './apiStatusCall';

function StatusTable({ title, initAI, initStaging, initTime, intervalTime, setIntervalTime }) {
    const [fileName, setFileName] = useState("");
    const [ai, setAi] = useState(initAI);
    const [staging, setStaging] = useState(initStaging);
    const [time, setTime] = useState(initTime);
    const [uploadMsg, setUploadMsg] = useState("");
    const fileRef = React.useRef("");

    useEffect(() => {
        setAi(initAI);
        setStaging(initStaging);
        setTime(initTime);
        fileRef.current.value = "";
        setUploadMsg("");
    }, [initAI, initStaging, initTime])

    useEffect(() => {
        aiStatus(title).then(res => setAi(res));
        stagingStatus(title).then(res => setStaging(res));
        setTime(new Date().toLocaleTimeString());
        if (intervalTime !== "off") {
            const interval = setInterval(() => {
                aiStatus(title).then(res => setAi(res));
                stagingStatus(title).then(res => setStaging(res));
                setTime(new Date().toLocaleTimeString());
            }, 10000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [intervalTime])

    const uploadFile = () => {
        setUploadMsg("Uploading");
        setIntervalTime("on");
        fileUpload(title, fileName).then(res => {
            fileRef.current.value = "";
            setUploadMsg(res);
            setIntervalTime("off");
        });
    }

    return (
        <div>
            <table className="table table-sm table-striped">
                <thead>
                    <tr>
                        <th colSpan={4}>
                            <div className="row">
                                <div className="col-sm-4">Upload file</div>
                                <div className="col-sm-4"><input type="file" className="form-control-file" ref={fileRef} onChange={(e) => setFileName(e.target.files[0])} accept=".csv" /></div>
                                <div className="col-sm-2"><button className="btn btn-secondary" onClick={() => uploadFile()} disabled={!fileRef.current.value}>Upload</button></div>
                                <div className="col-sm-2">{uploadMsg}</div>
                            </div>
                        </th>
                        <th colSpan={2}>
                            <div className="row">
                                <div className="col-sm-6">Refresh</div>
                                <div className="col-sm-6"><select className="form-control form-control-sm" id="refresh"
                                    value={intervalTime} onChange={(e) => setIntervalTime(e.target.value)}>
                                    <option defaultValue value="off" defaultChecked>off</option>
                                    <option defaultValue value="on" defaultChecked>on</option>
                                </select></div>
                            </div>
                        </th>
                    </tr>

                </thead>
                <tbody>
                    <tr>
                        <td>Access inventory</td><td>{initAI}</td>
                        <td>Staging</td><td>{initStaging}</td>
                        <td>Time</td><td>{initTime}</td>
                    </tr>
                    <tr>
                        <td>Access inventory</td><td>{ai}</td>
                        <td>Staging</td><td>{staging}</td>
                        <td>Time</td><td>{time}</td>
                    </tr>
                    <tr>
                        <td>New update</td><td>{ai - initAI}</td>
                        <td></td><td>{staging - initStaging}</td>
                        <td></td><td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
export default React.memo(StatusTable);