import React, { useEffect, useState } from 'react';
import { aiStatus, stagingStatus, fileUpload } from './apiStatusCall';
import { transactionStatus, vendorList, vendorReqParam } from '../../../data/constant';
import Loading from '../../common/loading/Loading';
import axios from '../../../api/transaction_api';
import FileDownload from 'js-file-download';

function StatusTable({ title, initAI, initStaging, initTime, intervalTime, setIntervalTime }) {
    const [fileName, setFileName] = useState("");
    const [info, setInfo] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [ai, setAi] = useState(initAI);
    const [staging, setStaging] = useState(initStaging);
    const [time, setTime] = useState(initTime);
    const [uploadMsg, setUploadMsg] = useState("");
    const fileRef = React.useRef("");
    const [value, setValue] = useState("");

    useEffect(() => {
        setAi(initAI);
        setStaging(initStaging);
        setTime(initTime);
        fileRef.current.value = "";
        setUploadMsg("");
    }, [initAI, initStaging, initTime])

    useEffect(() => {
        aiStatus(title).then(res => setAi(res));
        stagingStatus(title).then(res => setStaging(res));
        setTime(new Date().toLocaleTimeString());
        if (intervalTime !== "off") {
            const interval = setInterval(() => {
                aiStatus(title).then(res => setAi(res));
                stagingStatus(title).then(res => setStaging(res));
                setTime(new Date().toLocaleTimeString());
            }, 10000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [intervalTime])

    const uploadFile = () => {
        setUploadMsg("Uploading");
        setIntervalTime("on");
        fileUpload(title, fileName).then(res => {
            fileRef.current.value = "";
            setUploadMsg(res);
            setIntervalTime("off");
        });
    }

      const handleChange = (e) => {
        setValue(e.target.value);
      };

    const getDate = () => {
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      const date = today.getDate();
      return `${year}${month}${date}`;
    }

    const downLoadFile = (vendorValue) => {
            setLoading(true);
            vendorReqParam.forEach(function (vendor) {
              if(vendor.value == vendorValue) {
              setLoading(true);
              setInfo([]);
              setError("");
              axios.get(`/v2/support/downloadFile?vendor=${vendor.name}`, {
                      responseType: 'blob',
                   }).then(res => {
                   setLoading(false);
                   switch(vendor.name) {
                       case 'OPENNET_DELTA':
                         return FileDownload(res.data, "SP01_DELTA"+"_" + getDate() + ".csv");
                       case 'OPENNET_FULL':
                          return FileDownload(res.data, "SP01_DELTA"+"_" + getDate() + ".csv");
                       case 'TDC_MOBILE':
                           return FileDownload(res.data, "MobileAddressList"+"_" + getDate() + ".csv");
                       case 'TDC_FIBER':
                           return FileDownload(res.data, "FiberAddressList"+"_" + getDate() + ".csv");
                       case 'TDC_COPPER':
                           return FileDownload(res.data, "CopperAddressList"+"_" + getDate() + ".csv");
                       case 'TDC_COAX':
                           return FileDownload(res.data, "CoaxAddressList_Nuuday"+"_" + getDate() + ".csv");
                       case 'EWII':
                           return FileDownload(res.data, "EWIIAddressList"+"_" + getDate() + ".txt");
                       case 'FIBIA':
                           return FileDownload(res.data, "Fibia_Full_NuuOssi_WhiteList"+"-" + getDate() + ".csv");
                       default:
                         return "No Option Selected";
                     }
                  }).catch(err => {
                      setError(err.message);
                      setLoading(false);
                  });
              }
            });
        }

    return (
        <div>
          <div className="form-row">
              <div className="col-sm-4"><b>Download file</b></div>
              <label htmlFor="vendor">Vendor</label>
              <div className="col-sm-5"><select className="form-control form-control-sm" value={value} onChange={handleChange}>
                <option value="" defaultChecked>ALL</option>
                   {vendorList.map(ven => <option key={ven.value} value={ven.value}>{ven.name}</option>)}
              </select></div>
              <div className="col-sm-2"><button type="submit" className="btn btn-sm btn-secondary ml-2"
                                      onClick={() => downLoadFile(value)} disabled={!value}>{loading ? "Wait" : "Download File"}</button></div>
          </div>
            <table className="table table-sm table-striped">
                <thead>
                    <tr>
                        <th colSpan={4}>
                            <div className="row">
                                <div className="col-sm-4">Upload file</div>
                                <div className="col-sm-4"><input type="file" className="form-control-file" ref={fileRef} onChange={(e) => setFileName(e.target.files[0])} accept=".csv" /></div>
                                <div className="col-sm-2"><button className="btn btn-secondary" onClick={() => uploadFile()} disabled={!fileRef.current.value}>Upload</button></div>
                                <div className="col-sm-2">{uploadMsg}</div>
                            </div>
                        </th>
                        <th colSpan={2}>
                            <div className="row">
                                <div className="col-sm-6">Refresh</div>
                                <div className="col-sm-6"><select className="form-control form-control-sm" id="refresh"
                                    value={intervalTime} onChange={(e) => setIntervalTime(e.target.value)}>
                                    <option defaultValue value="off" defaultChecked>off</option>
                                    <option defaultValue value="on" defaultChecked>on</option>
                                </select></div>
                            </div>
                        </th>
                    </tr>

                </thead>
                <tbody>
                    <tr>
                        <td>Access inventory</td><td>{initAI}</td>
                        <td>Staging</td><td>{initStaging}</td>
                        <td>Time</td><td>{initTime}</td>
                    </tr>
                    <tr>
                        <td>Access inventory</td><td>{ai}</td>
                        <td>Staging</td><td>{staging}</td>
                        <td>Time</td><td>{time}</td>
                    </tr>
                    <tr>
                        <td>New update</td><td>{ai - initAI}</td>
                        <td></td><td>{staging - initStaging}</td>
                        <td></td><td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
export default React.memo(StatusTable);