import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import axios from '../../../api/transaction_api';
import Modal from '../../common/modal/Modal';
import RetriableTable from './RetriableTable';
import ErrorBody from '../../common/error/ErrorBody';


function DeltaDetails({ title, intervalTime }) {
    const [delta, setDelta] = useState({ data: [], msg: "" });
    const [error, setError] = useState('');
    const [intRetry, setIntRetry] = useState([]);
    const [extRetry, setExtRetry] = useState([]);
    const [validated, setValidated] = useState([]);
    const [validatedBlocked, setValidatedBlocked] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [pageId, setPageId] = useState(0);

    useEffect(() => {
        fetchDeltaInfo(0);
        // eslint-disable-next-line
    }, [title, intervalTime])


    function fetchDeltaInfo(pageVal) {
        setPageId(pageVal);
        setDelta({ data: [], msg: "loading" })
        axios.get(`/v2/transaction/getDeltaInfo?vendor=${title}&pageId=${pageVal}&pageSize=${100}`)
            .then(res => {
                if (res.data.length > 0) {
                    setDelta({ data: res.data, msg: "" });
                } else {
                    setDelta({ data: res.data, msg: "No data found" });
                }
            })
            .catch(e => {
                setDelta({ data: [], msg: "Unable to fetch data" })
            });
    }

    function fetchErrorTransaction(fileName) {
        setIntRetry([]);
        setExtRetry([]);
        setValidated([]);
        setValidatedBlocked([]);
        setLoading(true);
        setLoading2(true);
        axios.post("/v2/transaction/getTransaction", { fileName: fileName, transactionalStatus: "INTERNAL_ERROR" })
            .then(res => {
                setIntRetry(res.data);
                setLoading(false);
            }).catch(err => {
                setError(err.message);
                setLoading(false);
            });
        axios.post("/v2/transaction/getTransaction", { fileName: fileName, transactionalStatus: "EXTERNAL_ERROR" })
            .then(res => {
                setExtRetry(res.data);
                setLoading2(false);
            }).catch(err => {
                setError(err.message);
                setLoading2(false);
            });
    }

    const fetchValidateTransaction = (fileName) => {
        setIntRetry([]);
        setExtRetry([]);
        setValidated([]);
        setValidatedBlocked([]);
        setLoading(true);
        setLoading2(true);
        axios.post("/v2/transaction/getTransaction", { fileName: fileName, transactionalStatus: "VALIDATED" })
            .then(res => {
                setValidated(res.data);
                setLoading(false);
            }).catch(err => {
                setError(err.message);
                setLoading(false);
            });
        axios.post("/v2/transaction/getTransaction", { fileName: fileName, transactionalStatus: "VALIDATED_BLOCKED" })
            .then(res => {
                setValidatedBlocked(res.data);
                setLoading2(false);
            }).catch(err => {
                setError(err.message);
                setLoading2(false);
            });
    }

    const fileStatus = (d, status) => delta.data.filter(f => f.fileName === d && f.status.includes(status)).reduce((p, c) => p + c.total, 0);
    return (
        <div>
            <Modal id="retry-modal" title="Retriable Transaction"
                res={!loading && !loading2 ?
                    <RetriableTable retry={[intRetry, extRetry, validated, validatedBlocked]} />
                    : null}
                error={error ? <ErrorBody errorMessage={error} /> : ''} />
            <div className="text-right">
                <button className="btn btn-sm btn-secondary" disabled={pageId === 0} onClick={() => fetchDeltaInfo(pageId - 1)}>&lt;</button>
                <i className="h6 pr-2">page:{pageId}</i>
                <button className="btn btn-sm btn-secondary" disabled={delta.data.length===0} onClick={() => fetchDeltaInfo(pageId + 1)}>&gt;</button>
            </div>
            <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                <table className="table table-sm table-striped">
                    <thead>
                        <tr>
                            <th>{title} DELTA FILES<span ><button className="btn btn-outline-warning" onClick={() => fetchDeltaInfo(0)}>
                                <FontAwesomeIcon icon={faSync} /></button></span></th><th>Total</th><th>Complete</th><th>Validate</th><th>Error</th><th>Discard</th>
                        </tr>
                    </thead>
                    <tbody>
                        {delta.data.length > 0 ? [...new Set(delta.data.filter(d => !d.fileName.endsWith(".download")).map(d => d.fileName))].map((d, index) => <tr key={index}>
                            <td>{d}</td>
                            <td className="text-center">{delta.data.filter(f => f.fileName === d).map(del => del.total).reduce((sum, x) => sum * 1 + x * 1)}</td>
                            <td className="text-center">{fileStatus(d, "COMPLETE") > 0 ? fileStatus(d, "COMPLETE") : 0}</td>
                            <td className="text-center">{fileStatus(d, "VALIDATE") > 0 ?
                                <button className="btn btn-warning btn-block btn-sm" data-toggle="modal" data-target='#retry-modal' onClick={() => fetchValidateTransaction(d)}>
                                    {fileStatus(d, "VALIDATE")}</button> : 0}</td>
                            <td className="text-center">{fileStatus(d, "ERROR") > 0 ?
                                <button className="btn btn-danger btn-block btn-sm" data-toggle="modal" data-target='#retry-modal' onClick={() => fetchErrorTransaction(d)}>
                                    {fileStatus(d, "ERROR")}</button> : 0}</td>
                            <td className="text-center">{fileStatus(d, "DISCARD") > 0 ? fileStatus(d, "DISCARD") : 0}</td>
                        </tr>) : <tr><td colSpan={8} className="text-center">{delta.msg}</td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default React.memo(DeltaDetails);