import { useState } from 'react';
import { vendors } from '../../../data/constant';
import axios from '../../../api/transaction_api';
import ErrorBody from '../../common/error/ErrorBody';

export default function RetriableTable({ retry }) {
    const [done, setDone] = useState([]);
    const [inProgress, setInprogress] = useState([]);

    const retryTranc = (id) => {
        setInprogress(prev => [...prev, id]);
        axios.get(`/v1/whiteList/retryWhiteListLoading?transactionId=${id}`)
            .then(res => {
                if (res.data.transStatus === "COMPLETED" && !done.includes(id)) {
                    setDone(prev => [...prev, res.data.transactionId]);
                }
                setInprogress(inProgress.filter(i => i !== id));
            }).catch(e => setInprogress(inProgress.filter(i => i !== id)));
    }
    const retryAll = (info) => {
        info.map(r => retryTranc(r.transactionId));
    }
    const getTranc = [...retry].filter(r => r.length > 0);
    return (
        <div>
            {getTranc.length > 0 ? getTranc.map((r, index) => <table className="table table-striped table-sm" key={index}>
                <thead className="thead-light">
                    <tr>
                        <th>
                            {done.length > 0 && done.length === r.length ? <button className="btn btn-secondary btn-sm" disabled>Done</button>
                            : <button className="btn btn-danger btn-sm" onClick={() => retryAll(r)}>Retry All</button>}
                        </th>
                        <th>Id</th>
                        <th>Staging Id</th>
                        <th>Vendor</th>
                        <th>Status</th>
                        <th>message</th>
                    </tr>
                </thead>
                <tbody>
                    {r.map((d, index) => <tr key={index}>
                        <td>
                            {done.includes(d.transactionId) ?
                                <button className="btn btn-success btn-sm" disabled={true}>Done</button> :
                                inProgress.includes(d.transactionId) ?
                                    <button className="btn btn-outline-secondary btn-sm" disabled={true}>Wait</button> :
                                    <button className="btn btn-outline-danger btn-sm" onClick={() => retryTranc(d.transactionId)}>Retry</button>}
                        </td>
                        <td>{d.transactionId}</td>
                        <td>{d.stagingId}</td>
                        <td>{vendors.map(v => v.value === d.vendorID ? v.name : "")}</td>
                        <td> {done.includes(d.transactionId) ? "COMPLETED" : d.transStatus}</td>
                        <td>{d.transMsg}</td>
                    </tr>)}
                </tbody>
            </table>) : <ErrorBody errorMessage="No Retriable Transaction Found" />}
        </div>
    );
}