import React from 'react';

function ModalTable({tableData}){
    let tableKey=Object.keys(tableData);
    return (
        <table className="table table-striped table-bordered">
            <thead className="thead-light">
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
            </thead> 
            <tbody>
                {tableKey.length>0 ? tableKey.map(item=>{
                    return <tr key={item}>
                        <td>{item}</td>
                        <td>{tableData[item]}</td>
                    </tr>
                }):null}
            </tbody>
        </table>
    );
}

export default React.memo(ModalTable);