import React, { useState } from 'react';
import ErrorBody from '../../common/error/ErrorBody';
import axios from '../../../api/transaction_api';
import ReactDataTable from './ReactDataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Search from './Search';

function Details() {

    let d = new Date();
    d.setMonth(d.getMonth() - 2);

    const [tranData, setTranData] = useState([]);
    const [loading,setLoading]=useState(false);
    const [error, setError] = useState();
    const [formData, setFormData]=useState({
            startDate:"",
            endDate:"",
            vendor:"",
            transactionalId:"",
            transactionalStatus:"",
            installationId:"",
            addressUId:"",
            productCode:"",
            streetName:"",
            streetCode:"",
            houseIdentifier:"",
            municipalityCode:"",
            floorIdentifier:"",
            suiteIdentifier:"",
            postalCode:"",
            madId:"",
            pageSize:1000,
            pageId:0
    });


    const fetchTransaction = (pageNo) => {
        setTranData([]);
        setError();
        setLoading(true);
        setFormData(pre=>({...pre,pageId:pageNo}));
        axios.post("/v2/transaction/getTransaction",{
            ...formData,
            pageId:pageNo
        })
            .then(res => {
                setLoading(false);
                setTranData(res.data);
                if(res.data.length===0){
                    setError("No data found !")
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            })
    }

    return (
        <div className="card">
            <h5 className="card-header">Transaction Details</h5>
            <div className="card-body">
                <div className="accordion" id="accordionExample">
                    <div className="card">
                        <div className="card-header" id="headingOne" style={{backgroundImage:"linear-gradient(to right, #57b3c1, #495057 )", cursor:"pointer"}}
                         data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Search
                        </div>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body">
                                <Search formData={formData} setFormData={setFormData} fetchTransaction={fetchTransaction} row={tranData.length} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div-table">
                    {error ? <ErrorBody errorMessage={error} /> :
                        tranData.length>0 ? <ReactDataTable rowData={tranData} setRowData={setTranData} /> : 
                        <div className="text-center p-4 text-wrap">
                            <FontAwesomeIcon className="mb-3" icon={faSearch} size="5x"  color="rgb(87, 179, 193)"
                            spin={loading?true:false} />
                        <h2 style={{color:"rgb(73, 80, 87)"}}>
                            {loading?"Loading":"Search for Transaction details"}</h2>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default Details;