import React from 'react';
import { vendors, transactionStatus } from '../../../data/constant';

export const ReactTableFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
        <span className="mx-auto pt-2">
            {column.Header === "Vendor"
                ?
                <select className="form-control form-control-sm" style={{ minWidth: "70px" }} value={filterValue || ''} onChange={(e) => setFilter(e.target.value)}>
                    <option value="" defaultChecked>ALL</option>
                    {vendors.map(ven => <option key={ven.value} value={ven.name}>{ven.name}</option>)}
                </select>
                :
                column.Header === "Trans Status"
                    ?
                    <select className="form-control form-control-sm" style={{ minWidth: "70px" }} value={filterValue || ''} onChange={(e) => setFilter(e.target.value)}>
                        <option value="" defaultChecked>ALL</option>
                        {transactionStatus.map(item => <option key={item} value={item}>{item}</option>)}
                    </select>
                    :
                    <input className="form-control form-control-sm" style={{ minWidth: "70px" }}
                        value={filterValue || ''}
                        disabled={column.Header === "Action" || column.Header === "Details"}
                        onChange={(e) => setFilter(e.target.value)} />
            }
        </span>
    )
}