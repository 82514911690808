import axios from 'axios';
import {getToken} from './outhApi';

const AuthAxios = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*' },
})

AuthAxios.interceptors.request.use(req => {
    req.headers.authorization = `Bearer ${getToken()}`;
    return req;
});
export default AuthAxios;