import React, { useState } from 'react';
import { transactionStatus, vendorList, vendors } from '../../../data/constant';
import axios from '../../../api/transaction_api';

export default React.memo(function Search({ formData, setFormData, fetchTransaction, row }) {
    const [loading, setLoading] = useState(false);
    const [showMad, setShowMad] = useState(false);
    const [madDetails, setMadDetails] = useState({
        houseNumber: "",
        houseLetter: "",
        side: "",
        floor: "",
        streetName: "",
        postCode: ""
    });

    let resetFormData = () => {
        setFormData({
            startDate: "",
            endDate: "",
            vendor: "",
            transactionalId: "",
            transactionalStatus: "",
            installationId: "",
            addressUId: "",
            productCode: "",
            madId: "",
            pageSize: 1000,
            pageId: 0
        });
        resetMad();
    }

    const resetMad = () => {
        setMadDetails({
            houseNumber: "",
            houseLetter: "",
            side: "",
            floor: "",
            streetName: "",
            postCode: ""
        });
    }

    const handleMad = (e) => {
        setMadDetails(pre => ({ ...pre, [e.target.name]: e.target.value.trim() }));
    }

    const findMad = () => {
        setLoading(true);
        axios.post("/v1/masterAddressCache/getMadIDbyAddress", madDetails)
            .then(res => {
                setFormData({ ...formData, madId: res.data.addressSpecificId });
                setLoading(false);
            }).catch(() => {
                setFormData({ ...formData, madId: "Not Found" });
                setLoading(false);
            });
    }

    return (
        <div style={{ fontSize: "small" }}>
            <div className="form-row">
                <div className="form-group col-md">
                    <label htmlFor="vendor">Vendor</label>
                    <select className="form-control form-control-sm" id="vendor"
                        value={formData.vendor} onChange={(e) => setFormData({ ...formData, vendor: e.target.value })}>
                        <option value="" defaultChecked>ALL</option>
                        {vendors.map(ven => <option key={ven.value} value={ven.value}>{ven.name}</option>)}
                    </select>
                </div>
                <div className="form-group col-md">
                    <label htmlFor="transactionalId">Transactional Id</label>
                    <input type="text" className="form-control form-control-sm" id="transactionalId"
                        value={formData.transactionalId} onChange={(e) => setFormData({ ...formData, transactionalId: e.target.value })} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="transactionalStatus">Transactional Status</label>
                    <select className="form-control form-control-sm" id="transactionalStatus"
                        value={formData.transactionalStatus} onChange={(e) => setFormData({ ...formData, transactionalStatus: e.target.value })}>
                        <option value="" defaultChecked>ALL</option>
                        {transactionStatus.map(item => <option key={item} value={item}>{item}</option>)}
                    </select>
                </div>
                <div className="form-group col-md">
                    <label htmlFor="startDate">Start date</label>
                    <input type="date" placeholder="yyyy-MM-dd" className="form-control form-control-sm" id="startDate"
                        value={formData.startDate} onChange={(e) => setFormData({ ...formData, startDate: e.target.value })} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="endDate">End date</label>
                    <input type="date" className="form-control form-control-sm" id="endDate"
                        value={formData.endDate} onChange={(e) => setFormData({ ...formData, endDate: e.target.value })} />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md">
                    <label htmlFor="madId">Mad Id</label>
                    <div className="input-group">
                        <input type="text" className="form-control form-control-sm" id="madId"
                            value={formData.madId} onChange={(e) => setFormData({ ...formData, madId: e.target.value })} />
                        <div className="input-group-append" title={showMad ? "" : "find mad id by address"}>
                            <button className="btn btn-sm btn-secondary" type="button"
                                onClick={() => { setShowMad(pre => !pre); resetMad() }}>
                                {showMad ? "Hide" : "Find"}</button>
                        </div>
                    </div>

                    {/* <div style={{ display: "inline-flex" }}>
                        <input type="text" className="form-control form-control-sm" id="madId"
                            value={formData.madId} onChange={(e) => setFormData({ ...formData, madId: e.target.value })} />
                        <button className="btn btn-sm btn-secondary ml-1">Find</button>
                    </div> */}

                </div>
                <div className="form-group col-md">
                    <label htmlFor="installationId">Installation Id</label>
                    <input type="text" className="form-control form-control-sm" id="installationId"
                        title={formData.vendor ? "" : "please select vendor"}
                        readOnly={!formData.vendor}
                        value={formData.installationId} onChange={(e) => setFormData({ ...formData, installationId: e.target.value })} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="addressUId">External Address Id</label>
                    <input type="text" className="form-control form-control-sm" id="addressUId"
                        title={formData.vendor ? "" : "please select vendor"}
                        readOnly={!formData.vendor}
                        value={formData.addressUId} onChange={(e) => setFormData({ ...formData, addressUId: e.target.value })} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="productCode">Product Code</label>
                    <input type="text" className="form-control form-control-sm" id="productCode"
                        title={formData.vendor || formData.vendor === "2" ? "" : "please select Opennet"}
                        readOnly={!formData.vendor || formData.vendor !== "2"}
                        value={formData.productCode} onChange={(e) => setFormData({ ...formData, productCode: e.target.value })} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="pageSize">No of Records</label>
                    <select className="form-control form-control-sm" id="pageSize"
                        value={formData.pageSize} onChange={(e) => setFormData({ ...formData, pageSize: e.target.value, pageId: 0 })}>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                    </select>
                    {/* <input type="number" className="form-control form-control-sm" id="pageSize"
                        value={formData.pageSize} onChange={(e) => setFormData({ ...formData, pageSize: e.target.value,pageId:0 })} /> */}
                </div>
            </div>
            {showMad && <div className="div-table mb-3" style={{ background: "#f0f1f375" }}>
                <div className="form-row">
                    <div className="form-group col-md">
                        <label htmlFor="madId">Street Name</label>
                        <input type="text" className="form-control form-control-sm" name="streetName"
                            value={madDetails.streetName} onChange={handleMad} />
                    </div>
                    <div className="form-group col-md">
                        <label htmlFor="installationId">House no</label>
                        <input type="text" className="form-control form-control-sm" name="houseNumber"
                            value={madDetails.houseNumber} onChange={handleMad} />
                    </div>
                    <div className="form-group col-md">
                        <label htmlFor="installationId">House letter</label>
                        <input type="text" className="form-control form-control-sm" name="houseLetter"
                            value={madDetails.houseLetter} onChange={handleMad} />
                    </div>
                    <div className="form-group col-md">
                        <label htmlFor="installationId">Side</label>
                        <input type="text" className="form-control form-control-sm" name="side"
                            value={madDetails.side} onChange={handleMad} />
                    </div>
                    <div className="form-group col-md">
                        <label htmlFor="installationId">Floor</label>
                        <input type="text" className="form-control form-control-sm" name="floor"
                            value={madDetails.floor} onChange={handleMad} />
                    </div>
                    <div className="form-group col-md">
                        <label htmlFor="installationId">Post Code</label>
                        <input type="text" className="form-control form-control-sm" name="postCode"
                            value={madDetails.postCode} onChange={handleMad} />
                    </div>

                </div>
                <div className="form-row">
                    <div className="col-sm" style={{ textAlign: "center" }}>
                        <button type="submit" className="btn btn-sm btn-secondary ml-2"
                            onClick={() => findMad()} disabled={loading}>{loading ? "Wait" : "Get MadId"}</button>
                    </div>
                </div>
            </div>}
            <div className="form-row">
                <div className="col-sm" style={{ textAlign: "center" }}>
                    <button type="submit" className="btn btn-sm btn-secondary float-left"
                        disabled={formData.pageId <= 0}
                        onClick={() => fetchTransaction(formData.pageId - 1)}>&lt;Page({formData.pageId - 1})</button>
                    <button type="submit" className="btn btn-sm btn-secondary"
                        onClick={() => fetchTransaction(0)}>Search</button>
                    <button type="submit" className="btn btn-sm btn-secondary ml-2"
                        onClick={() => resetFormData()}>Reset</button>
                    <button type="submit" className="btn btn-sm btn-secondary float-right"
                        disabled={row < formData.pageSize}
                        onClick={() => fetchTransaction(formData.pageId + 1)}>Page({formData.pageId + 1}) &gt;</button>
                </div>
            </div>
        </div>
    );
});