import React, { useState } from "react";
import { useTable, useSortBy, useFilters } from 'react-table';
import { format } from 'date-fns';
import { ReactTableFilter } from './ReactTableFilter';
import axios from '../../../api/transaction_api';
import Modal from '../../common/modal/Modal';
import Notify from '../../common/modal/Notify';
import ModalTable from './ModalTable';
import ErrorBody from '../../common/error/ErrorBody';
import { vendors } from '../../../data/constant';

function ReactDataTable({ rowData, setRowData }) {

  const [rowId, setRowId] = useState();
  const [modal, setModal] = useState({ title: 'title', data: '' });
  const [error, setError] = useState();
  const [notifyDate, setNotifyData] = useState({ title: 'title' });

  let fetchDetails = (tranc, stage, vendor) => {
    setModal({ title: `Transaction(${tranc}) details:`, data: '' });
    setError();
    if (vendor === 1) {
      axios.get(`/v1/whiteList/tdcnet/getTdcnetWhiteListEntry?stagingId=${stage}`).then(res => {
        setModal({ title: `Transaction(${tranc}) for TDC staging id (${stage}):`, data: res.data });
      }).catch(err => {
        setError(err.message);
      })
    } else if (vendor === 2 || vendor === 5 || vendor === 6 || vendor === 7) {
      axios.get(`/v1/whiteList/getOpennetWhiteListEntry?stagingId=${stage}`).then(res => {
        setModal({ title: `Transaction(${tranc}) for Opennet staging id (${stage}):`, data: res.data });
      }).catch(err => {
        setError(err.message);
      })
    } else if (vendor === 3 || vendor === 8) {
      axios.get(`/v1/whiteList/fibia/getFibiaWhiteListEntry?stagingId=${stage}`).then(res => {
        setModal({ title: `Transaction(${tranc}) for FIBIA staging id (${stage}):`, data: res.data });
      }).catch(err => {
        setError(err.message);
      })
    } else if (vendor === 4) {
      axios.get(`/v1/whiteList/ewii/getEwiiWhiteListEntry?stagingId=${stage}`).then(res => {
        setModal({ title: `Transaction(${tranc}) for EWII staging id (${stage}):`, data: res.data });
      }).catch(err => {
        setError(err.message);
      })
    } else if (vendor === 9) {
      axios.get(`/v1/whiteList/getCoaxWhiteListEntry?stagingId=${stage}`).then(res => {
        setModal({ title: `Transaction(${tranc}) for COAX staging id (${stage}):`, data: res.data });
      }).catch(err => {
        setError(err.message);
      })
    } else {
      setError("Not implemented");
    }
  }

  let retryRequest = (id) => {
    setNotifyData({ title: "Waiting for execute...!" });
    axios.get(`/v1/whiteList/retryWhiteListLoading?transactionId=${id}`).then(res => {
      if (res.data) {
        setRowData(pre => (pre.map(data => data.transactionId === id ? res.data : data)));
        setNotifyData({ title: "Transuction updated...!" });
      }
    }).catch(err => {
      setNotifyData({ title: "Retry failed...!" });
    })
  }
  let notifyRetry = (id) => {
    setNotifyData(
      {
        title: `Do you want to retry request id: ${id}?`,
        method: () => retryRequest(id)
      });
  }

  const data = React.useMemo(
    () => rowData ? rowData.map(data => {
      return {
        transactionId: data.transactionId,
        stagingId: data.stagingId,
        vendor: vendors.map(v => v.value === data.vendorID ? v.name : ""),
        createdTime: data.createdTime,
        modifiedTime: data.modifiedTime,
        userId: data.userId,
        fileName: data.fileName,
        madId: data.madId,
        action: { id: data.transactionId, transStatus: data.transStatus },
        transStatus: data.transStatus,
        transMsg: data.transMsg,
        details: { tranc: data.transactionId, stage: data.stagingId, vendor: data.vendorID, transStatus: data.transStatus, fileName: data.fileName }
      }
    }) : [],
    [rowData]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Action', Footer: 'Action', accessor: 'action',
        Cell: ({ value }) => {
          return value.transStatus.includes("INTERNAL_ERROR") && value.transMsg !== "Unofficial Address"
            && < button className="btn btn-secondary btn-sm"
              data-toggle="modal" data-target='#notifyModal'
              onClick={() => notifyRetry(value.id)
              }>
              Retry
         </button >
        }
      },
      { Header: 'Id', Footer: 'Id', accessor: 'transactionId' },
      {
        Header: 'Details', Footer: 'Details', accessor: 'details',
        Cell: ({ value }) => <button className={value.transStatus.includes("ERROR") ? "btn btn-error btn-sm" : "btn btn-secondary btn-sm"}
          data-toggle="modal" data-target='#tran-modal'
          onClick={() => fetchDetails(value.tranc, value.stage, value.fileName.includes("COAX") ? 9 : value.vendor)}>
          view
        </button>
      },
      { Header: 'Vendor', Footer: 'Vendor', accessor: 'vendor' },
      { Header: 'mad Id', Footer: 'mad Id', accessor: 'madId' },
      { Header: 'Staging Id', Footer: 'Staging Id', accessor: 'stagingId' },
      { Header: 'Trans Status', Footer: 'Trans Status', accessor: 'transStatus' },
      { Header: 'Trans Msg', Footer: 'Trans Msg', accessor: 'transMsg' },
      { Header: 'File Name', Footer: 'File Name', accessor: 'fileName' },
      { Header: 'User Id', Footer: 'User Id', accessor: 'userId' },
      {
        Header: 'Created Time', Footer: 'Created Time', accessor: 'createdTime',
        Cell: ({ value }) => { return format(new Date(value), 'yyyy-MM-dd HH:mm:ss') }
      },
      {
        Header: 'Modified Time', Footer: 'Modified Time', accessor: 'modifiedTime',
        Cell: ({ value }) => { return format(new Date(value), 'yyyy-MM-dd HH:mm:ss') }
      }
    ],
    // eslint-disable-next-line
    []
  )

  const defaultColumn = React.useMemo(() => {
    return {
      Filter: ReactTableFilter
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows
    //  page,
    //  nextPage,
    //  previousPage,
    //  canNextPage,
    //  canPreviousPage,
    //  pageOptions,
    //  gotoPage,
    //   setPageSize,
    //  state
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useFilters,
    useSortBy,
    //  usePagination,
  )

  // const {pageIndex,pageSize} =state;

  return (
    <>
      <div style={{ position: 'relative', overflow: 'scroll', textAlign: 'center', maxHeight: "70vh" }}>
        <Modal id="tran-modal" title={modal.title}
          res={modal.data ? <ModalTable tableData={modal.data} /> : ''}
          error={error ? <ErrorBody errorMessage={error} /> : ''} />
        <Notify id="notifyModal" notifyData={notifyDate} />

        <table {...getTableProps()}
          className="table table-sm table-striped table-bordered">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <span  {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr className={rowId === row.id ? 'select-row' : ''} {...row.getRowProps()} onClick={() => setRowId(row.id)}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>

          <tfoot>
            {footerGroups.map(group => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <th {...column.getFooterProps()}>{column.render("Footer")}</th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>

        {/* <table  className="table">
        <tbody>
        <tr>
        <td>

        <span style={{display: 'inline-flex',alignItems:'center'}}>
            Rows:
         <select className="form-control ml-2" value={pageSize} onChange={e=> setPageSize(Number(e.target.value))}>
                {
                    [10,25,50,100].map(pageSize=>(
                        <option key={pageSize} value={pageSize}>
                             {pageSize}
                        </option>
                    ))
                }
         </select>
         </span>

        </td>
        <td>
            <button className="btn btn-secondary mx-1" onClick={()=>previousPage()} disabled={!canPreviousPage}>Previous</button>
            <span className="mr-2">Page{' '} <strong>{pageIndex +1} of {pageOptions.length}</strong>{' '}</span>
            <button className="btn btn-secondary mx-1" onClick={()=>nextPage()} disabled={!canNextPage}>Next</button>  
        </td>
        <td>

        <span style={{display: 'inline-flex',alignItems:'center'}}>
         Go to page:
         
             <input type='number' defaultValue={pageIndex+1} className="form-control ml-2"
                onChange={e=>{
                    const pageNumber=e.target.value?Number(e.target.value)-1:0
                    gotoPage(pageNumber)
                }}
             style={{width:'70px'}}/>
        </span>
        </td>
        <td>
            <button className="btn btn-secondary mx-1" onClick={()=>pageFn(hPage-1)} disabled={hPage<=0?true:false}>-</button>
            <span className="mr-2">{rowData.length>0?<strong> From {' '} {rowData[0].transactionId} To {rowData[rowData.length-1].transactionId}</strong>
            :<strong>No data found</strong>}{' '}</span>
            <button className="btn btn-secondary mx-1" onClick={()=>pageFn(hPage+1)} disabled={rowData.length<=0?true:false}>+</button>  
        </td>
      </tr>
      </tbody>
      </table> */}
      </div>
    </>
  )
}

export default React.memo(ReactDataTable);